<template>
  <div id="hfa-affiliate">
    <Navbar class="white-text light-menu-icon" />

    <div class="background">
      <img src="../assets/affiliate-background.svg" alt="page background" />
    </div>

    <section id="become-affiliate">
      <div class="affiliate">
        <h1 class="page-title">Become an HFA Affiliate</h1>
        <p>
          Join a team of world class experts in every area of mechanical
          licensing, tracking, reporting and payments.
        </p>
        <router-link
          :to="{ name: 'create-hfa-account', params: { type: 'apply' } }"
          class="button primary shadow"
          >APPLY TODAY</router-link
        >
        <div class="fee">$100 NON-REFUNDABLE application fee will apply</div>
      </div>
      <AffiliateBenefits />
    </section>

    <div class="graph">
      <img src="../assets/screen-and-graphs.svg" alt="Become an Affiliate" />
    </div>

    <Footer />
  </div>
</template>

<script>
import AffiliateBenefits from "@/components/AffiliateBenefits.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HfaAffiliate",
  components: {
    AffiliateBenefits,
    Navbar,
    Footer
  },
  metaInfo: function() {
    return {
      title: " - Become an HFA Affiliate",
      meta: [
        {
          name: "description",
          content:
            "Join a team of world class experts in every area of mechanical licensing, tracking, reporting and payments."
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  }
};
</script>

<style lang="scss">
#hfa-affiliate {
  > .background {
    width: 100vw;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
    text-align: right;

    img {
      width: 400vw;
      transform: translate(-20%, -2%);
    }

    @media (min-width: $desktop) {
      img {
        width: 97vw;
        transform: none;
      }
    }
  }
  section {
    h3 {
      font-family: "Antic Slab", serif;
      font-weight: 400;
      font-size: 1.75em;
    }
    .button {
      font-size: 1.375em;
      margin-top: 7.687vw;
      padding: 0.8em 0;
      width: 260px;
      display: block;
    }
  }

  #become-affiliate {
    margin: 50px 0;
    display: flex;
    flex-direction: column;

    .affiliate {
      h1 {
        font-size: 1.25em;
        letter-spacing: 0.2px;
        margin-top: 0;
        margin-bottom: 3.001vw;
      }
      p {
        font-size: 1em;
        line-height: 1.89;
        width: 200px;
        margin: 30px 0;
      }
      a {
        font-size: 1.25em;
        // width: 100%;
        margin: 0 auto;
      }
      .fee {
        margin: 5px 10px 0;
        display: block;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 1;
        position: relative;
        border-radius: 50px;
      }
      @media (min-width: $desktop) {
        a {
          width: 200px;
          margin: 0;
        }
        .fee {
          margin: 5px 0 0;
          padding: 0;
          box-shadow: none;
          background-color: transparent;
          display: inline-block;
          text-align: center;
        }
      }
    }
    .benefits-container {
      margin-top: 100px;

      #benefits {
        background-color: transparent;

        .benefit {
          color: #fff;

          img {
            filter: invert(100%);
          }
          h2 {
            color: #fff;
          }
        }
      }
    }

    @media (min-width: $desktop) {
      margin: 100px 0 0;
      flex-direction: row;

      .affiliate {
        h1 {
          font-size: 2.25em;
        }
        p {
          font-size: 1.1875em;
          width: 487px;
        }
        a {
          width: 260px;
        }
      }
    }
  }

  .graph {
    img {
      display: none;

      @media (min-width: $desktop) {
        width: 800px;
        display: block;
        margin: 0 auto;
        transform: translateY(-25%);
      }
    }
  }

  #footer {
    margin-top: -10vh;

    @media (min-width: $desktop) {
      margin-top: 20vh;
    }
  }
}
</style>
